@for $i from 1 through 4 {
  .ml-#{$i},
  .mx-#{$i} {
    margin-left: $i * 4px;
  }

  .mr-#{$i},
  .mx-#{$i} {
    margin-right: $i * 4px;
  }

  .mt-#{$i},
  .my-#{$i} {
    margin-top: $i * 4px;
  }

  .mb-#{$i},
  .my-#{$i} {
    margin-bottom: $i * 4px;
  }
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.m-0 {
  margin: 0;
}


@for $i from 1 through 4 {
  .p-#{$i} {
    padding: $i * 4px;
  }

  .pl-#{$i},
  .px-#{$i} {
    padding-left: $i * 4px;
  }

  .pr-#{$i},
  .px-#{$i} {
    padding-right: $i * 4px;
  }

  .pt-#{$i},
  .py-#{$i} {
    padding-top: $i * 4px;
  }

  .pb-#{$i},
  .py-#{$i} {
    padding-bottom: $i * 4px;
  }
}


.d-flex {
  display: flex;
}
