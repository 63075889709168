.iframe{
  height: 100%;
  width: 100%;
  border: 0;

}

.iframeWrapper{
  height:100%;
  width:100%;
  display:flex;
  justify-content: center;
  flex-direction: column
}

