.bulk-actions {
  position: absolute;
  bottom: 15%;
  z-index: 9;
  left: 50%;
  transform: translate(-50%, 32px);
  opacity: 0;
  visibility: hidden;
  background-color: #fff;
  padding: 8px 16px;
  box-shadow: 0 0 20px 0 rgb(0, 0, 0, 0.19);
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.bulk-actions--active{
  transform: translate(-50%, 0);
  opacity: 1;
  visibility: visible;
}
