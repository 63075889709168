.filter {
  padding: 12px;
  display: flex;
}

.filterDropdown {
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgb(0, 0, 0, 0.19);
  border-radius: 5px;
  padding: 16px;

  :global {
    .ant-form-item {
      margin-bottom: 12px;
    }
  }
}
