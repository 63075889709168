.content {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: url('../../assets/images/intro.jpg') no-repeat center center;
  background-size: cover;
}

.block {
  background-color: rgba(#fff, 0.95);
  padding: 48px 24px;
  border-radius: 4px;
  width: 360px;

  img {
    height: 36px;
    margin-bottom: 32px;
  }
}
