.layout {
  height: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  img {
    height: 70px;
  }
}

.content {
  padding: 16px;
  height: 100%;
}

.avatar {
  border-radius: 50%;
  background-color: #fff;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  opacity: 0.8;
  color: #ff8300;
  overflow: hidden;

  @media (max-width: 480px) {
    display: none;
  }

  img {
    height: 32px;
  }
}
