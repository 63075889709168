@import 'partials/spacers';
@import 'partials/text';
@import 'partials/bulk-actions';
@font-face {
  font-family: 'Calibri';
  src: local('Calibri'),
       url(../../public/fonts/calibri.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

html,
body,
#root {
  height: 100%;
  font-family: Calibri, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;

}

.bg-white {
  background-color: #fff;
  border: 1px solid #f0f0f0;
}

.ant-layout {
  &.ant-layout-has-sider {
    width: 100%;
    min-width: 0;
  }
}

.ant-layout-sider {
  max-width: 250px !important;
  min-width: 250px !important;
  width: 250px !important;
  
  &.ant-layout-sider-collapsed {
    max-width: 80px !important;
    min-width: 80px !important;
    width: 80px !important;
  }
}
